.inactive {
  pointer-events: none;
}

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v140/flUhRq6tzZclQEJ-Vdg-IuiaDsNcIhQ8tQ.woff2) format('woff2');
}
@font-face {
  font-family: 'Vollkorn';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.googleapis.com/css2?family=Vollkorn:ital,wght@0,400..900;1,400..900&display=swap) format('woff2');
}
.disabled {
  cursor: not-allowed;
}

._rel {
  position: relative;
}
._f {
  display: flex;
  gap: 0.8em;
}

._ftop {
  display: flex;
  gap: 0.8em;
  align-items: flex-start;
}

._fc {
  display: flex;
  gap: 0.8em;
  justify-content: center;
}

._fr {
  display: flex;
  justify-content: flex-end;
}

._fspace {
  display: flex;
  justify-content: space-between;
}

._fm {
  display: flex;
  gap: 0.8em;
  align-items: center;
}

._fcc {
  display: flex;
  gap: 0.8em;
  justify-content: center;
  align-items: center;
}

._fv {
  display: flex;
  gap: 0.8em;
  flex-direction: column;
}

._fh {
  display: flex;
  gap: 0.8em;
  flex-direction: row;
}

._f100 {
  display: flex;
  gap: 0.8em;
  width: 100%;
  flex: 1 1 100%;
}

._f50 {
  display: flex;
  gap: 0.8em;
  width: 50%;
  flex: 1 0 50%;

}

._fgap0 {
  display: flex;
  gap: 0em;
}
._fgap02 {
  display: flex;
  gap: 0.2em;
}

._fgap04 {
  display: flex;
  gap: 0.4em;
}

._fgap04 {
  display: flex;
  gap: 0.2em;
}

._fgap1 {
  display: flex;
  gap: 1em;
}

._fgap2 {
  display: flex;
  gap: 2em;
}

._fgap3 {
  display: flex;
  gap: 3em;
}
._fgap4 {
  display: flex;
  gap: 4em;
}

._fgrow {
  display: flex;
  flex-grow: 1;
}

._fnogrow {
  display: flex;
  flex-grow: 0;
}

._fwrap {
  display: flex;
  flex-wrap: wrap;
}

._fnowrap {
  display: flex;
  flex-wrap: nowrap;
}

._fshrink {
  display: flex;
  flex-shrink: 1;
}

._fnoshrink {
  display: flex;
  flex-shrink: 0;
}

._fright {
  display: flex;
  align-self: flex-end;
  align-items: flex-end;
  justify-content: flex-end;
  text-align: right;
}

._t08 {
  font-size: 0.8em;
}

._tl {
  text-align: left;
}

._tc {
  text-align: center;
}

._tr {
  text-align: right;
}
._t12 {
  font-size: 1.2em;
}

._o08 {
  opacity: .8;
}

._o06 {
  opacity: .6;
}

._o04 {
  opacity: .4;
}

._o02 {
  opacity: .2;
}

.tnowrap {
  white-space: nowrap;
}

.ellipsis {
  max-width: 100%;
  display: inline;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}




._ico,
.t_icon {
  letter-spacing: 0;
  text-transform: none;
  justify-content: center;
  align-self: center;
  align-items: center;
  font-family: Material Icons, sans-serif;
  font-weight: 400;
  line-height: 1em;
  display: flex;
}

.t_icon.t_icon--20 {
  letter-spacing: 0;
  text-transform: none;
  object-fit: cover;
  justify-content: center;
  max-width: 2em;
  font-size: 1.4em;
  font-weight: 400;
  display: flex;
  position: relative;
  overflow: hidden;
}

.t_icon.t_icon--check {
  opacity: .58;
  letter-spacing: 0;
  text-transform: none;
  object-fit: cover;
  max-width: 2em;
  margin-right: 0;
  font-size: 15px;
  font-weight: 400;
  overflow: hidden;
}
._mvhi,
._mvh,
._mh,
.mobile_vh_yes,
.mobile_vhi_yes,
.mobile_h_yes {
  display: none;
}



@media screen and (max-width: 767px) {

  ._mvh,
  .mobile_vh_yes {
    display: block !important;
  }

  ._mvh,
  .mobile_h_yes {
    display: block !important;
  }
    .mobile_h_no {
      display: none;
    }

}



@media screen and (max-width: 479px) {

  ._mv,
  .mobile_v_yes {
    display: flex !important;
  }

  ._mv_,
  .mobile_v_no {
    display: none !important;
  }

}

._pd,
._pd1 {
  padding: 1em;
}

._pdh1 {
  padding: 0 1em;
}

._pdv1 {
  padding: 1em 0;
}

._pd2 {
  padding: 2em;
}

._pdh2 {
  padding: 0 2em;
}

._pdv2 {
  padding: 2em 0;
}
:root {
  --var-bg: #ffedcc;
}
/* Default font size */
:root {
  --font-size: 16px;
}

/* Media query for larger screens */
@media (min-width: 768px) {
  :root {
    --font-size: 14px;
  }
}

@media (min-width: 1024px) {
  :root {
    --font-size: 18px;
  }
}

.ant-select-selector {
  padding: var(--select-padding, 16px);
  /* Adjust this value */
}

.ant-input {
  padding: var(--input-padding, 16px);
  /* Adjust this value */
}
.x_videos_item0 .x_video_play_icon {
  opacity: 0 !important;
}

.x_videos_item0:hover .x_video_play_icon {
  opacity: 1 !important;
}
.leaf-container {
  position: relative;
}
::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d4d0cd62;
  border-radius: 20px;
  border: 7px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #d4d0cd62;
}
.my-masonry-grid {
  display: flex;
  margin-left: -2px;
  /* Adjust based on your column gap */
  width: auto;
  flex-wrap: wrap;
}

.my-masonry-grid_column {
  padding-left: 2px;
  background-clip: padding-box;
}



.my-masonry-grid_item {
  margin-bottom: 2px;
  border-radius: 1em;
  width: 100%;
}
.my-masonry-grid.my-masonry-grid--books {
  margin-left: -3em;
}

.my-masonry-grid_column.my-masonry-grid_column--books {
  padding-left: 3em;
}
.my-masonry-grid_item.my-masonry-grid_item--books {
  margin-bottom: 3em;
  border-radius: 3em;
    padding-bottom: 1em;
}
@media screen and (max-width: 479px) {

.my-masonry-grid_item_img {
    max-width: 60%;
  }
  .my-masonry-grid_column,
  .my-masonry-grid_item {
    min-width: 50%;
  }
.my-masonry-grid_column--books,
.my-masonry-grid_item--books {
  min-width: 100%;
}

.my-masonry-grid.my-masonry-grid--books {
  margin-left: -2em;
}

.my-masonry-grid_column.my-masonry-grid_column--books {
  padding-left: 2em;
}
}